import React from 'react';
import {
  NumberInput, Create, SimpleForm,
  TextInput, ReferenceInput, SelectInput
} from 'react-admin';

export default (props) => {
  return (
    <Create title="Create a Wind Turbine" {...props} mutationMode="pessimistic">
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="identifier" />

        <TextInput source="latitude" />
        <TextInput source="longitude" />

        <NumberInput source="maxPower" defaultValue={62} />

        <ReferenceInput
          label="Type"
          reference="turbineTypes"
          source="type.id"
          perPage={1000}
        >
          <SelectInput optionText="name"/>
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
