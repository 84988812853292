import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const redirect = (basePath, id, data) => `/turbineTypes`;

export default (props) => (
  <Create title="Create a new Turbine type" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="name" />
      <TextInput source="url" />
    </SimpleForm>
  </Create>
);
