import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@mui/material';
const  Modal = ({handleClose, image}) => {
  const [open, setOpen] = React.useState(true);

  const closeModal = () => {
    setOpen(false);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           <img style={{ maxHeight: "500px", maxWidth: "500px", height: "auto", width: "auto" }} src={image} alt={image} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Modal;
