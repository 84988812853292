import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin';

export default (props) => (
  <Create title="Create a new User" {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />

      <SelectInput source="role" defaultValue="customer" choices={[
        { id: 'ADMIN', name: 'ADMIN' },
        { id: 'CUSTOMER', name: 'CUSTOMER' },
        { id: 'SERVICE_MAN', name: 'SERVICE_MAN' },
      ]} />

    </SimpleForm>
  </Create>
);
