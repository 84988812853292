import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_CHECK } from 'react-admin';

import {axios} from '../api';

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    return axios.post(`/auth/login`, {
      email: username,
      password: password
    }, {
      timeout: 5000
    })
      .then((data) => {
        const { token, user } = data.data;
        
        localStorage.setItem('token', token);
        localStorage.setItem('role', user.role);
      })
      .catch(error => console.error(error));

  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    // ...
    // localStorage.removeItem('token');
    // localStorage.removeItem('role');
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  }
  return Promise.reject('Unknown method');
};
