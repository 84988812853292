import React from 'react';
import { styled } from '@mui/styles';
import { Card, CardActionArea, CardMedia } from '@mui/material';
import Modal from './Modal';

const StyledCard = styled(Card)({
    maxWidth: 150,
});
const StyledCardMedia = styled(CardMedia)({
    height: 40,
    width: 40,
});

const ImageCard = ({url}) => {

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    }
    const openModal = () => {
        setOpen(true);
    }

    return (
    <div style={{float: "left", margin: "2px 2px 2px 0px"}}>
        <StyledCard onClick={openModal}>
            <CardActionArea>
                <StyledCardMedia image={url} title="Zoom Image"/>
            </CardActionArea>
        </StyledCard>
        {open && (
            <Modal image={url} handleClose={handleClose} />
        )}

    </div>
    );
}

export default ImageCard;
