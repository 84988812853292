import React from 'react';
import {
  List, Datagrid, TextField, EditButton, ReferenceField, ChipField, DateField, Filter, TextInput, ReferenceInput, SelectInput
} from 'react-admin';
import ImageCard from '../components/Image';
import {DateTimeField} from "@mui/x-date-pickers";

const CustomImageField = ({ record }) => {

  if (record.photo != null) {
    const photos = record.photo
    return (
      <div style={{ width: "150px" }}>
        {photos.length > 0 && photos.map((photo, index) => (
          <ImageCard key={`photo-${index}`} url={photo} />
        ))}
      </div>
    )

  } else {
    return <div></div>;
  }
}
const ListFilters = props => (
  <Filter {...props} >
    <TextInput
      label="Search by notes"
      source="notes"
      alwaysOn
    />
    <ReferenceInput
      label="User name"
      source="userId"
      reference="users"
      sort={{ field: 'name', order: 'ASC' }}
      filterToQuery={searchText => ({ name: searchText })}
      allowEmpty={true}
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Wind Turbine"
      source="windTurbineId"
      reference="windTurbines"
      sort={{ field: 'name', order: 'ASC' }}
      filterToQuery={searchText => ({ name: searchText })}
      allowEmpty={true}
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <DateTimeField
      source="createdAt_gte"
      format="M/dd/yyyy, HH:mm:ss"
      label="Time Range Min"
      alwaysOn
    />
    <DateTimeField
      source="createdAt_lte"
      format="M/dd/yyyy, HH:mm:ss"
      label="Time Range Min"
      alwaysOn
    />
  </Filter>
);

export default (props) => (
  <List {...props} filters={<ListFilters />} sort={{ field: 'updatedAt', order: 'DESC' }} >
    <Datagrid>
      <ReferenceField label="User" source="user.id" reference="users" link="edit">
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField label="Wind Turbine" source="windturbine.id" reference="windTurbines" link="edit">
        <ChipField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <TextField source="notes" />
      <CustomImageField source="photo" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);
