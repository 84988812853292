import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const TurbineType = ({ record }) => {
  return <span>Turbine type {record ? `"${record.name}"` : ''}</span>;
};

export default (props) => (
  <Edit title={<TurbineType />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="url" />
    </SimpleForm>
  </Edit>
);
