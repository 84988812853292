import { API_URL } from "../constants";
import axios from 'axios';
import { fetchUtils, HttpError } from 'react-admin';

const client = axios.create({
  baseURL: API_URL
});

client.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers = {
        authorization: `Bearer ${token}`
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = localStorage.getItem("token");
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
  }

  return fetchUtils.fetchJson(url, options).catch(err => {
    if (err.body && err.body.error) {
      throw new HttpError(err.body.error)
    } else {
      throw err;
    }
  });
};

export {
  client as axios,
  httpClient
};