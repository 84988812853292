import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

import { WindTurbineCreate, WindTurbineList, WindTurbineEdit } from './windTurbines';
import { UserCreate, UserList, UserEdit } from './users';
import { TurbineTypeCreate, TurbineTypeEdit, TurbineTypeList } from './turbineTypes';
import { ServiceLogEdit, ServiceLogsList, ServiceLogsCreate } from './serviceLogs';

import authProvider from './providers/authProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { API_URL } from './constants';
import { httpClient } from './api';

function renderFullPermissionView() {
  return [
    <Resource name="users" options={{ label: 'Users' }} list={UserList} edit={UserEdit} create={UserCreate} />,
    <Resource name="windTurbines" options={{ label: 'Wind Turbines' }} list={WindTurbineList} edit={WindTurbineEdit} create={WindTurbineCreate} />,
    <Resource name="turbineTypes" options={{ label: 'Turbine Types' }} list={TurbineTypeList} edit={TurbineTypeEdit} create={TurbineTypeCreate} />,
    <Resource name="serviceLogs" options={{ label: 'Service Logs' }} list={ServiceLogsList} edit={ServiceLogEdit} create={ServiceLogsCreate} />,
    <Resource name="ActionLog" />
  ]
}

function renderLimitedPermissionView() {
  return [
    <Resource name="windTurbines" options={{ label: 'Wind Turbines' }} list={WindTurbineList} />,
    <Resource name="turbineTypes" />,
    <Resource name="users" />
  ]
}
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { dataProvider: null };
  }
  componentDidMount() {
    this.setState({
      dataProvider: simpleRestProvider(API_URL, httpClient)
    })
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return <div>Loading</div>;
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Admin dataProvider={dataProvider} authProvider={authProvider}>
          {permissions => (
            permissions === 'ADMIN'
              ? renderFullPermissionView()
              : renderLimitedPermissionView()
          )}
        </Admin>
      </LocalizationProvider>
    );
  }
}

export default App;
