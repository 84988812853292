import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, DateTimeInput } from 'react-admin';


const redirect = (basePath, id, data) => `/serviceLogs`;
const transform = (data) => {
  if (data.windturbine) {
    data.windTurbineId = data.windturbine.id;
  }

  if (data.user) {
    data.userId = data.user.id;
  }

  return data;
}

export default (props) => (
  <Create title="Create a new serviceLog" {...props} transform={transform}>
    <SimpleForm redirect={redirect}>
      <DateTimeInput source="createdAt" defaultValue={new Date()} />
      <ReferenceInput label="User" source="user.id" reference="users" perPage={1000} >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Wind Turbine" source="windturbine.id" reference="windTurbines" perPage={1000}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput multiline source="notes" style={{ width: '100%' }} />
    </SimpleForm>
  </Create>
);
