import React from 'react';
import {
  List, Datagrid, TextField,
  EditButton
} from 'react-admin';

import SearchFilter  from '../components/SearchFilter';

export default (props) => (
  <List {...props} filters={<SearchFilter />}>
    <Datagrid>
      {/* <TextField source="id" /> */}
      <TextField source="name" />
      <TextField source="url" />
      {/* <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />  */}
      <EditButton />
    </Datagrid>
  </List>
);
