import React, { Fragment } from 'react';
import {
  ReferenceArrayField, List, Datagrid, ReferenceField, TextField,
  EditButton, ChipField, SingleFieldList, BulkDeleteButton
} from 'react-admin';

import SearchFilter from '../components/SearchFilter';

const getBulkActionButtons = (props) => (
  props?.permissions !== "ADMIN"
    ? false
    : <Fragment>
      <BulkDeleteButton {...props} />
    </Fragment>
)

export default (props) => (
  <List {...props} filters={<SearchFilter />} filter={{ belongToUser: props.permissions !== 'ADMIN' }} bulkActionButtons={getBulkActionButtons(props)}>
    <Datagrid>
      <TextField source="identifier" />
      <TextField source="name" />
      <ReferenceArrayField reference="users" source="userIds">
        <SingleFieldList linkType={props.permissions !== 'ADMIN' ? false : "show"}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField label="Type" source="type.id" reference="turbineTypes" link={props.permissions !== 'ADMIN' ? false : "show"}>
        <ChipField source="name" />
      </ReferenceField>
      { props.permissions === 'ADMIN' && <EditButton /> }
    </Datagrid>
  </List>
);
