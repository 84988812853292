import React from 'react';
import { Filter, TextInput } from 'react-admin';
import PropTypes from "prop-types";

const SearchFilter = ({ propertyToSearch, ...otherProps }) => (
  <Filter {...otherProps}>
    <TextInput
      label={`Search by ${propertyToSearch}`}
      source={propertyToSearch}
      alwaysOn
    />
  </Filter>
);

SearchFilter.propTypes = {
  propertyToSearch: PropTypes.string,
};

SearchFilter.defaultProps = {
  propertyToSearch: 'name'
};

export default SearchFilter;
