import React from 'react';
import {
  AutocompleteArrayInput, Edit, SimpleForm, 
  TextInput, ReferenceArrayInput, SelectInput
} from 'react-admin';

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.name}"` : ''}</span>;
};

const PasswordField = ({ record }) => {
  record.password = "";
  return (
    <span></span>
  )
}

export default (props) => (
  <Edit title={<UserTitle />} {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="email" />
      <TextInput source="name" />
      <TextInput source="password" label="Change Password" />
      <PasswordField source="password" />

      <SelectInput source="role" choices={[
        { id: 'ADMIN', name: 'ADMIN' },
        { id: 'CUSTOMER', name: 'CUSTOMER' },
        { id: 'SERVICE_MAN', name: 'SERVICE_MAN' },
      ]} />

      <ReferenceArrayInput label="Wind Turbines"
        source="windTurbineIds" reference="windTurbines" filterToQuery={q => ({})}>
        <AutocompleteArrayInput />
      </ReferenceArrayInput>

    </SimpleForm>
  </Edit>
);
