import React from 'react';
import {
  List, Datagrid, TextField, EditButton,
  ReferenceArrayField, SingleFieldList, ChipField, DateField
} from 'react-admin';

import SearchFilter  from '../components/SearchFilter';

export default (props) => (
  <List 
    {...props} 
    filters={<SearchFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="role" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />

      <ReferenceArrayField reference="windTurbines" source="windTurbineIds">
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ReferenceArrayField>

      <EditButton />
    </Datagrid>
  </List>
);
