import { styled } from '@mui/styles';
import React from 'react';
import {
  AutocompleteArrayInput, Edit, SimpleForm, Toolbar, SaveButton, DeleteButton,
  TextInput, NumberInput, ReferenceArrayInput, SelectInput, ReferenceInput
} from 'react-admin';

const WindTurbineTitle = ({ record }) => {
  return <span>Wind Turbine {record ? `"${record.name}"` : ''}</span>;
};

const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between',
});

const CustomToolbar = props => (
  <StyledToolbar {...props}>
      <SaveButton />
      {props?.permissions === "ADMIN" && <DeleteButton mutationMode="pessimistic" /> }
  </StyledToolbar>
);
export default (props) => (
  <Edit title={<WindTurbineTitle />} {...props} mutationMode="pessimistic">
    <SimpleForm toolbar={<CustomToolbar {...props} />}>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput source="identifier" />

      <ReferenceArrayInput label="Users" source="userIds" reference="users" filterToQuery={q => ({})}>
        <AutocompleteArrayInput />
      </ReferenceArrayInput>

      <TextInput source="latitude" label="Latitude" />
      <TextInput source="longitude" label="Longitude" />

      <NumberInput source="maxpower" format={v => Math.round(v*100)/100} defaultValue={62} />

      <ReferenceInput label="Type" reference="turbineTypes" source="type.id">
        <SelectInput optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
