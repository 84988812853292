import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, DateTimeInput } from 'react-admin';

const ServiceLog = ({ record }) => {
  return <span>Edit Service Log</span>;
};

export default (props) => (
  <Edit title={<ServiceLog />} {...props}>
    <SimpleForm>
      <DateTimeInput source="createdAt" />
      <ReferenceInput label="User" source="user.id" reference="users" perPage={1000} >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Wind Turbine" source="windturbine.id" reference="windTurbines" perPage={1000}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput multiline source="notes" style={{ width: '100%' }}/>
    </SimpleForm>
  </Edit>
);
